import { Component,OnInit } from '@angular/core';
import { MatDialog }  from '@angular/material';
import { DialogExampleComponent } from './dialog-example/dialog-example.component';
declare var window: any; // Needed on Angular 8+
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent { 
  constructor(public dialog: MatDialog) {}
  openDialog(){
    this.dialog.open(DialogExampleComponent);
  }
  ngOnInit(){
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    if (baseUrl === "https://evaluation.leah.care"){
      this.dialog.open(DialogExampleComponent,{disableClose:true,
      autoFocus:false});
    }
  }
  
}
