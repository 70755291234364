import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HashLocationStrategy, PathLocationStrategy } from '@angular/common';

import { ClientComponent } from './client/client.component';

const routes: Routes = [
	{ path: '', component: ClientComponent },
	{ path: '', 	redirectTo: '', pathMatch: 'full' }, 
	{ path: '**', 	redirectTo: '' }
];

export const APP_ROUTES_PROVIDER: any[] = [];
export const APP_ROUTES: ModuleWithProviders = RouterModule.forRoot( routes );
export const APP_LOC_STRATEGY: any = PathLocationStrategy; // vs HashLocationStrategy