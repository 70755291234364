import { Renderer, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Response } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser';

import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { DynamicFormControlModel, DynamicFormService, DynamicFormGroupModel, DynamicInputModel, DynamicRadioGroupModel, DynamicCheckboxModel, DynamicCheckboxGroupModel, DynamicSelectModel, DynamicSwitchModel, DynamicTextAreaModel, DynamicDatePickerModel } from "@ng-dynamic-forms/core";
import * as moment from 'moment/moment';

import { DatasService, WindowService } from '../app.services';
import { positiveNumberValidator, numberRangeValidator, timeHoursValidator, timeMinutesValidator } from "../app.validators";
import { STORAGE_ID, VERSION, CREDENTIALS, CONTEXTUAL_MESSAGES } from '../app.config';
import { isNull } from 'util';
import { $ } from 'protractor';

@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
	
	local_store: 			any;

	data_index: 			number = 0;

	form_data: 				any;
	form_model: 			any;
	form_groups: 			any = {};
	form_answers: 			any = [];
	form_answers_map: 		any = {};
	form_resume: 			any;
	form_downloadable_resume: 	any;

	manual_collapsed: 		any = {};

	contextual_message: 	string = '';
	
	next_allowed:			boolean = false;
	consult_ended:			boolean = false;
	updating: 				boolean = false;
	spinner:				boolean = false;
	parsedUrl = new URL(window.location.href);
    baseUrl = this.parsedUrl.origin;
	suivant_tr: 			string;
	end_tr: 				string;
	expl_ques_tr:				string;
	oui:				string = "Oui";
	non:				string = "Non";
	oui_tr:				string;
	non_tr:				string;
	requis_tr: 			string;
	back_beginning_tr:		string;
	previous_form_tr:		string;
	validate_tr:				string;
	et_tr:					string;
	invalid_tr:			string;
	invalid_phrase_tr: 	string;
	superior_tr:		string;
	inferior_tr:		string;
	between_tr:		string;
	at_least_tr:		string;
	char_tr:			string;	


	constructor( protected router:Router, private render:Renderer, private elRef:ElementRef, private storage:LocalStorageService, private formService: DynamicFormService, private datas_service:DatasService, private sanitizer: DomSanitizer ) { }

	ngOnInit() {
		//this.local_store = this.storage.retrieve(STORAGE_ID);

		
		 //if(CREDENTIALS.jeton==''){ this.storage.clear(STORAGE_ID); }
		

		// this.data_index = (this.local_store && this.local_store.index)? this.local_store.index : 0;
		this.data_index = 0;

		//console.log('local_storage:',this.local_store);

		this.getForm();

	}

	getForm(data=null){
		
		//if(CREDENTIALS.jeton==''){ CREDENTIALS.jeton = (this.local_store && this.local_store.jeton!=='')? this.local_store.jeton : CREDENTIALS.cle; }

		if(CREDENTIALS.jeton=='') CREDENTIALS.jeton = CREDENTIALS.cle;

		this.updating = true;

		let request = this.getFormRequest(data);

		console.log('jeton:',CREDENTIALS.jeton,'request:',request);

		this.datas_service.updateForm( request ).subscribe( (res: Response) => { this.initForm(res.json()); this.spinner = false;},
				  			  								(err: any) => { console.log('failed to load datas | ', err); this.spinner = false;} );

	}

	initDownloadResume(){
		this.datas_service.updateForm( this.getFormRequest('download') ).subscribe( (res: Response) => { this.handleDownloadResumeReady(res); },
				  			  								  				  		(err: any) => { console.log('failed to download resume | ', err); } );
	}
	handleDownloadResumeReady(r){
		this.form_downloadable_resume = this.sanitizer.bypassSecurityTrustUrl('data:text/plain;charset=utf-8,' + encodeURIComponent(r._body));
	}

	initForm(data){

		console.log('======================================\n> données '+data.page+' | form-page N°'+(this.data_index+1)+'\n======================================');
		
		if(data.page=='login'){ 
			
			//if(this.local_store){ this.storage.clear(STORAGE_ID); }
			
			CREDENTIALS.jeton = CREDENTIALS.cle;

			this.getForm();

		} else{
			if(data.langue === "fr" || data.langue === "frp") {
				this.suivant_tr = "Passer à la suite";
				this.end_tr = "Fin du questionnaire";
				this.expl_ques_tr = "Vous trouverez ci-dessous le document synthétisant les réponses apportées au questionnaire.";
				this.oui_tr = "Oui";
				this.non_tr = "Non";
				this.requis_tr = "réponse requise";
				this.back_beginning_tr = "formulaire précédent";
				this.previous_form_tr = "previous form";
				this.validate_tr = "Valider la saisie";
				this.invalid_tr = "Valeur non valide";
				this.invalid_phrase_tr = this.invalid_tr + ". La valeur saisie doit";
				this.superior_tr= "être supérieure à"	
				this.inferior_tr = "être inférieure à"		
				this.between_tr = "être comprise entre"		
				this.et_tr = "et"
				this.at_least_tr = "avoir au moins"		
				this.char_tr = "caractères"
			} else if (data.langue == "en" || data.langue == "enp"){
				this.suivant_tr = "Next";
				this.end_tr = "End of the questionnaire";
				this.expl_ques_tr = "You will find below the document summarizing the answers to the questionnaire.";
				this.oui_tr = "Yes";
				this.non_tr = "No";
				this.requis_tr = "answer required";
				this.back_beginning_tr = "back to the beginning";
				this.previous_form_tr = "previous form";
				this.validate_tr = "Validate input";
				this.invalid_tr = "Invalid value"
				this.invalid_phrase_tr = this.invalid_tr + ". The value must";
				this.superior_tr= "be greater than"	
				this.inferior_tr = "be less than"		
				this.between_tr = "be between"	
				this.et_tr = "and"
				this.at_least_tr =	"have at least"	
				this.char_tr = "characters"
			}

			CREDENTIALS.jeton = data.jeton;
			CREDENTIALS.questionnaire_id = data.questionnaire_id;
			this.storage.store(STORAGE_ID, { jeton: data.jeton, index: this.data_index } );

			if(data.formulaire.liste_contenu){ 
			
				let i,k,d,model;
				
				this.form_data = data.formulaire;
				this.form_resume = this.sanitizer.bypassSecurityTrustHtml(data.compte_rendu);
				this.form_answers.splice(0);
				
				for(k in this.manual_collapsed){ this.manual_collapsed[k] = null; delete this.manual_collapsed[k]; }

				console.log('> '+data.formulaire.liste_contenu.length+' questions | données reçues:\n======================================');
				console.log(this.form_data);
				console.log('======================================');
		
				model = this.initModel(data);

				this.form_model = this.buildForm(model);

				for (i = 0; i < this.form_model.length; ++i) {
					d = this.form_model[i];
					this.form_groups[d[0].id] = this.createFormGroup(d);
				}
				console.log('> model',model);
				console.log('> form_answers',this.form_answers);
				console.log('> form_answers_map',this.form_answers_map);
				console.log('> form_model',this.form_model);
				console.log('> form_groups',this.form_groups);
				
				// this.next_allowed = this.isNextAllowed();
				this.next_allowed = (data.suivant_ok==1);
				this.consult_ended = (data.message_id==10 || data.suivant_ok==-1);

				if(this.consult_ended){ this.initDownloadResume(); }

			}else{ console.log('!!! liste_contenu vide !!!\n======================================'); }

			this.updating = false;

		}

	}
	initModel(data){		
		let i, l, n, d, d2, fe, qs, model = {};		
		for (i = 0; i < data.formulaire.liste_contenu.length; i++){			
			n = i + 1;
			d = data.formulaire.liste_contenu[i];
			qs = [];			
			fe = this.getFormBasicEntry(d);
			qs.push( { id:('question-'+d.id), structure:[fe] } );
			model['form_step_'+n] = { id:'section-'+n, content:qs };
		}
		return model;
	}
	createFormGroup(fm){ return this.formService.createFormGroup(fm); }
	getFormBasicEntry(data){

		let i, d, vs, dd,hh,mm, g, cls_confs = {}, result = {};
		
		cls_confs['date'] = { element: { control: "date-input" }, grid: { control: "date-input-content", host: "date-input-ctn" } };
		cls_confs['time'] = { element: { control: "time-input" }, grid: { control: "time-input-content", host: "time-input-ctn" } };
						
		console.log('+ create form-entry '+data.type_question_id+((data.type_question2_id)?(':'+data.type_question2_id) : '')+' |',((data.value)? ('value:'+data.value) : 'no-value'));
		console.log('------------------------------------');
		
		switch(data.type_question_id){ 

			case 1:
				// texte court
				result['type'] = 'input';
				result['input'] = { id: data.id.toString(), label:'', inputType:'', placeholder:'', value:this.getDefaultValue(data) };
				break;

			case 2:
				// texte
				result['type'] = 'textarea';
				result['input'] = { id: data.id.toString(), label:'', inputType:'', minLength:data.nb_car_min > 0 ? data.nb_car_min : null, placeholder:'', value:this.getDefaultValue(data), 
				validators: { minLength:data.nb_car_min } 
				};
				
				// ---
				break;

			case 3:
				// liste deroulante
				result['type'] = 'select';
				result['input'] = { id: data.id.toString(), label:'', inputType:'', placeholder:'', value:this.getDefaultValue(data), options:this.getOptions(data.liste_contenu) };
				break;

			case 4:
				// radio
				result['type'] = 'radiogroup';
				result['input'] = { id: data.id.toString(), label:'', inputType:'', placeholder:'', value:this.getDefaultValue(data), options:this.getOptions(data.liste_contenu) };
				break;

			case 5:

				// checkbox / checkboxgroup
				result['type'] = (data.liste_contenu.length==1)? 'checkbox' : 'checkboxgroup';

				if(data.liste_contenu.length==1){ 
					result['input'] = { id: data.id.toString(), label:data.libelle1, inputType:'', placeholder:'', value:this.getDefaultValue(data) };
				}else{ 
					g = [];
					for (i = 0; i < data.liste_contenu.length; i++) {
						d = data.liste_contenu[i];
						vs = data.valeur.split(',');
						g.push( new DynamicCheckboxModel( { id:d.id.toString(), label:d.libelle1, value:(vs.indexOf(d.id.toString())>-1) } ) );
					}
					result['input'] = { id: data.id.toString(), label:'', group:g };
					this.getDefaultValue(data);
				}

				break;

			case 6:
				// oui/non
				result['type'] = 'radiogroup';
				result['input'] = { id: data.id.toString(), label:'', inputType:'', placeholder:'', value:this.getDefaultValue(data), options:this.getOptions( [ { id:this.oui, libelle1:this.oui_tr, libelle2:'' }, { id:this.non, libelle1:this.non_tr, libelle2:'' } ] ) };
				break;

			case 7:
				// nombre
				result['type'] = 'input';
				result['input'] = { id: data.id.toString(), label:'', inputType:'number', min:data.nombre_min, max:(data.nombre_max>0)?data.nombre_max:null, placeholder:'', value:(data.valeur)? data.valeur:null, 
				validators: (data.nombre_max>0)? { min:data.nombre_min, max:data.nombre_max } : { min:data.nombre_min }
				};
				this.getDefaultValue(data);
				break;

			case 8:
				// date et/ou heure
				switch(data.type_question2_id){

					case 1:

						// date et heure
						// ---
						// data ref.
						// data.valeur = '2017-12-10T20:05';
						// ---
						dd = (data.valeur)? this.getDate(data.valeur) : '';
						hh = (data.valeur)? this.getHours(data.valeur) : '';
						mm = (data.valeur)? this.getMinutes(data.valeur) : '';
						
						g = [];
						g.push( new DynamicDatePickerModel( { id:'DD-'+data.id.toString(), label:'', required: true, value:dd }, cls_confs['date'] ) );
						g.push( new DynamicInputModel( { id:'HH-'+data.id.toString(), label:'', inputType:'number', suffix:'heures', disabled:((dd==null)), min:0, max:23, required: false, value:hh.toString(), validators:{timeHoursValidator:{ name:timeHoursValidator.name, args: null} } }, cls_confs['time'] ) );
						g.push( new DynamicInputModel( { id:'MM-'+data.id.toString(), label:'', inputType:'number', suffix:'minutes', disabled:((dd==null)), min:0, max:59, required: false, value:mm.toString(), validators:{timeMinutesValidator:{ name:timeMinutesValidator.name, args: null} } }, cls_confs['time'] ) );
						
						result['type'] = 'group';
						result['input'] = { id: data.id.toString(), label:'', group:g };

						this.getDefaultValue(data);

						break;

					case 2:

						// date seule
						// ---

						result['type'] = 'date';
						result['input'] = { id: data.id.toString(), label:'', inputType:'date', placeholder:'', value:(data.valeur)? this.getDate(data.valeur) : null };						
						
						this.getDefaultValue(data);

						break;


					case 3:

						// heure seule
						// ---
						
						hh = (data.valeur)? this.getHours(data.valeur) : '';
						mm = (data.valeur)? this.getMinutes(data.valeur) : '';
						
						g = [];

						g.push( new DynamicInputModel( { id:'HH-'+data.id.toString(), label:'', inputType:'number', suffix:'heures', min:0, max:23, required: false, value:hh.toString(), validators:{timeHoursValidator:{ name:timeHoursValidator.name, args: null} } }, cls_confs['time'] ) );
						g.push( new DynamicInputModel( { id:'MM-'+data.id.toString(), label:'', inputType:'number', suffix:'minutes', min:0, max:59, required: false, value:mm.toString(), validators:{timeMinutesValidator:{ name:timeMinutesValidator.name, args: null} } }, cls_confs['time'] ) );

						result['type'] = 'group';
						result['input'] = { id: data.id.toString(), label:'', group:g };

						this.getDefaultValue(data);

						break;

				}

				break;

			case 9:

				switch(data.type_question2_id){

					case 3:
						// RECOMMANDATION
						result['type'] = 'recommandation';
						result['input'] = { id: data.id.toString(), label:'', inputType:'text', placeholder:'', value:this.getDefaultValue(data) };
						break;

					case 4:
						// ALERTE
						result['type'] = 'alerte';
						result['input'] = { id: data.id.toString(), label:'', inputType:'text', placeholder:'', value:this.getDefaultValue(data) };
						break;

				}

				break;

		}

		console.log('- form-entry:',result);
		console.log('------------------------------------');
		
		return result;
	}
	getDefaultValue(data){
		let id = data.id, value = (data.valeur)? data.valeur : '', ref_value = this.splitVal(value), //ref_value = this.stripVal(value),
			result = (ref_value!=='')? (this.isNumber(ref_value)? parseInt(ref_value) : ref_value) : ((data.type_question2_id==3 || data.type_question2_id==4)? '-':''), 
			d = { id:id, value: result, required: (data.obligatoire_id==1) };
		console.log('- register answer N°',id, (d.required)? 'as requided':'as not requided', '| current value:', (d.value)? result:'none');
		this.form_answers.push(d);
		this.form_answers_map[id] = this.form_answers[this.form_answers.length-1];
		this.form_answers_map[id].updatable = true;
		return result;
	}
	getOptions(data){ let i, d, options = []; for (i = 0; i < data.length; i++) { d = data[i]; options.push( { label:d.libelle1, value:d.id } ); } return options; }
	getComent(data){ return data.libelle2; }
	getAlert(data){ return data.libelle2; }
	getBadge(index, type_question, alert_id){ return ((type_question==9 && alert_id==3)? 'info' : (type_question==9 && alert_id==4)? 'error' : (index+1)); }
	getDate(data){ return data.substring(0, 10); }
	getHours(data){ return parseInt(data.substring(data.length-4, data.length-2)); }
	getMinutes(data){ return parseInt(data.substring(data.length-2, data.length)); }
	splitVal(v){ let i,r = [], vs = v.split(','); for(i=0; i<vs.length; i++){ if(vs[i]!==''){ r.push(vs[i])} } return r.toString(); }
	stripVal(v){ return v.replace(/,/g,''); }
	formatDate(d){ return moment(d).format('YYYY-MM-DD'); }
	formatDateTime(d,h,m){ return d + ((h==0&&m==0)? '' : ('T'+((h>9)? h.toString() : ('0'+h.toString()))+((m>9)? m.toString() : ('0'+m.toString())))); }
	isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 
	isRequired(data){ return (data.obligatoire_id==1)? true : false; }
	isNextAllowed(){ let i, data, n = 0, result; for (i = 0; i < this.form_answers.length; ++i) { data = this.form_answers[i]; if(data.value!=='' || !data.required){ n++; } }; result = (n==this.form_answers.length); this.contextual_message = (!result)? CONTEXTUAL_MESSAGES['answer_required']:''; return result; }
	capitalize(s){ return s.charAt(0).toUpperCase() + s.slice(1); }
	uncapitalize(s){ return s.charAt(0).toLowerCase() + s.slice(1); }

	getFormRequest(data){
		let k, values = [];

		if(!data){
			if(!data && CREDENTIALS.mode==2){ return (CREDENTIALS.jeton!=='' && CREDENTIALS.jeton!==CREDENTIALS.cle)? null : { cde:'sansSvg' }; }
			else if(!data && CREDENTIALS.mode==1){ return (CREDENTIALS.jeton!=='')? null : {  }; }
		} else{
			if(data=='first'){ return { cde:'d' }; }
			else if(data=='prev'){ return { cde:'p'}; }
			else if(data=='next'){ return { cde:'s'}; }
			else if(data=='download'){ return { cde:'cr' }; }
			else if(data!=='first' && data!=='prev' && data!=='next' && data!=='download' && typeof(data.value)!=='object'){ 
				return { cde: 'e', q_id:data.id, val:data.value }; 
			}
			else if(data!=='first' && data!=='prev' && data!=='next' && data!=='download' && typeof(data.value)=='object'){ 
				for(k in data.value){ values.push(k); }
				return { cde: 'e', q_id:data.id, val:values }; 
			}
		}
	}
	getFormUrlEncoded(o) {
		let k, ek, ev, result = [];
		for (k in o) { ek = encodeURIComponent(k); ev = encodeURIComponent(o[k]); result.push(ek + '=' + ev); }
		return result.join('&');
	}


	buildForm(ref_model){
		let k, model = [];		
		for(k in ref_model){ model.push( this.initFormSections(ref_model[k]) ); }
		return model;
	}
	initFormSections(form_step){
		let i, data, type, sections:Array<DynamicFormGroupModel> = [];
		for (i = 0; i < form_step.content.length; ++i) { sections.push( this.initFormSection(form_step.content[i]) ); }
		return sections;
	}
	initFormSection(form_section){
		let i, data, type, form_group = [];
		for (i = 0; i < form_section.structure.length; ++i) {
			data = form_section.structure[i];
			type = this.getFormInput(data.type);
			if(type){ form_group.push( new type(data.input) ); }
		}
		return new DynamicFormGroupModel( { id: form_section.id, group: form_group } );
	}
	getFormInput(type):any{
		let input_type;
		switch (type) {
			case 'select': input_type = DynamicSelectModel; break;
			case 'radiogroup': input_type = DynamicRadioGroupModel; break;
			case 'input': input_type = DynamicInputModel; break;
			case 'textarea': input_type = DynamicTextAreaModel; break;
			case 'alerte': input_type = DynamicTextAreaModel; break;
			case 'checkbox': input_type = DynamicCheckboxModel; break;
			case 'checkboxgroup': input_type = DynamicCheckboxGroupModel; break;
			case 'switch': input_type = DynamicSwitchModel; break;
			case 'date': input_type = DynamicDatePickerModel; break;
			case 'group': input_type = DynamicFormGroupModel; break;
		}
		return input_type;
	}

	onChange($event) {
    	let self = this, i,k,vs,vid,r, D,dd,H,hh,M,mm, min, max, minLength, err_msg, apply_update, value, id = (($event.model.type=='CHECKBOX' && $event.model.parent.type=='CHECKBOX_GROUP') || (($event.model.parent.type=='GROUP') && ($event.model.type=='DATEPICKER' || $event.model.type=='INPUT')))? $event.model.parent.id : $event.model.id;
    	
		// console.log($event);
		
	    if($event.control.valid){
			
	    	if($event.model.type=='CHECKBOX' && $event.model.parent.type=='CHECKBOX_GROUP'){ 
				value = "";
				for (i = 0; i < $event.model.parent.group.length; ++i) {
					if ($event.model.parent.group[i]._value){
						value = value + (value.length > 0 ? ',' : "")  + $event.model.parent.group[i].id
					}
				}

				apply_update = false;	  
				
	    	} else if($event.model.type=='DATEPICKER' && $event.model.parent.type!=='GROUP'){ 

	    		value = this.formatDate($event.model.value); 
	    		apply_update = false;

	    	}else if(($event.model.type=='DATEPICKER' || $event.model.type=='INPUT') && $event.model.parent.type=='GROUP'){ 
	    		
	    		if($event.model.parent.group.length==1 && $event.model.type=='DATEPICKER'){

	    			// > datepicker seul
	    			D = $event.model.parent.group[0].value;

		    		dd = this.formatDate( (D)? D : moment() );
		    		hh = moment(D).hours();
		    		mm = moment(D).minutes();

		    		id = $event.model.id;
	    		
					value = this.formatDateTime(dd,hh,mm);
	    			apply_update = false;

	    		}else if($event.model.parent.group.length==2){

	    			// > timepicker seul
	    			H = $event.model.parent.group[0].value;
		    		M = $event.model.parent.group[1].value;

		    		dd = this.formatDate(moment());
		    		hh = (H)? H : moment(D).hours();
		    		mm = (M)? M : moment(D).minutes();
	    		
					value = this.formatDateTime(dd,hh,mm);
	    			apply_update = false;

	    		}else if($event.model.parent.group.length==3){

	    			// > datetimepicker
	    			D = $event.model.parent.group[0].value;
		    		H = $event.model.parent.group[1].value;
		    		M = $event.model.parent.group[2].value;

		    		dd = this.formatDate( (D)? D : moment() );
		    		hh = (H)? H : moment(D).hours();
		    		mm = (M)? M : moment(D).minutes();
	    		
	    			value = this.formatDateTime(dd,hh,mm);
	    			apply_update = false;

	    		}else if($event.model.parent.group.length==1 && $event.model.type=='INPUT'){
	    			
	    			// input seule
		    		id = $event.model.id;
	    			value = $event.model.value;
	    			apply_update = false;

	    		}

	    		// console.log(id,D,H,M,'->',dd,hh,mm,'=>',value);
				// console.log(this.form_answers_map);

			} else if ($event.model.type=='TEXTAREA') {
				value = $event.model.value;
				apply_update = false;
			} else { 
				
	    		value = $event.model.value;
	    		apply_update = true;
	    	}

			console.log('------------\n-> change on question N°'+$event.model.id + ': => value:',value, ' / update:',apply_update);
	    	// console.log(this.form_answers_map);
	    	
	    	this.form_answers_map[id].valid = true;
	    	this.form_answers_map[id].value = value;
	    	this.form_answers_map[id].updatable = apply_update;

	        // console.log(this.form_answers);

	        if(apply_update){
	        	this.manual_collapsed[id] = true;
	        	setTimeout(function(){ self.getForm(self.form_answers_map[id]); }, 150);	        	
	        }

	    }else{
	    	id = $event.model.id;
	    	value = $event.model.value;
	    	min = $event.model.min;
			max = $event.model.max;
			minLength = $event.model.minLength;


	    	if(min!==undefined && max>0){ err_msg = `${this.invalid_phrase_tr} ${this.between_tr} ${min} ${this.et_tr} ${max}.`; }
			else if(min!==undefined && (max==0 || !max)){ err_msg = `${this.invalid_phrase_tr} ${this.superior_tr} ${min}.`; }
			else if(max!==undefined && (min==0 || !min)){ err_msg = `${this.invalid_phrase_tr} ${this.inferior_tr} ${max}.`; }
			else if(minLength!==undefined){ err_msg = `${this.invalid_phrase_tr} ${this.at_least_tr} ${minLength} ${this.char_tr}.`; }
	    	else{ err_msg = this.invalid_tr; }
	    	
	    	this.form_answers_map[id].error_message = err_msg;
	    	this.form_answers_map[id].valid = false;

	    	console.log('------------\n-> !!! question N°' + id + ' : invalid entry ' + min +':'+value+':'+ max + ' !!!', this.form_answers_map[id]);

	    }

    }

    getStepClasses(index){    	
		let cs, step_collapsed, step_answered = this.isStepAnswered(index), step_introduced = this.isStepIntroduced(index);

    	if( step_answered 
    		&& this.form_data.liste_contenu[index].type_question_id!==9
    		&& this.manual_collapsed[this.form_data.liste_contenu[index].id]==undefined 
    		&& this.form_answers_map[this.form_data.liste_contenu[index].id].updatable){
    		this.manual_collapsed[this.form_data.liste_contenu[index].id] = true; 
    	}
    	step_collapsed =  this.isStepCollapsed(index);    	
    	 //console.log('question '+index+' | collapsed:'+step_collapsed+':'+this.form_answers_map[this.form_data.liste_contenu[index].id].updatable+':'+this.manual_collapsed[this.form_data.liste_contenu[index].id]+' | answered:'+step_answered+' | inroduced:'+step_introduced+' | type: '+this.form_data.liste_contenu[index].type_question_id+' / answer: '+this.form_answers[index].value.toString().length+' / mc: '+this.manual_collapsed[this.form_data.liste_contenu[index].id]);
    	cs = {
	    	'mat-stepper-recommandation': (this.form_data.liste_contenu[index].type_question_id==9 && this.form_data.liste_contenu[index].type_question2_id==3),
			'mat-stepper-alert': (this.form_data.liste_contenu[index].type_question_id==9 && this.form_data.liste_contenu[index].type_question2_id==4),
			'mat-stepper-collapsed': step_collapsed,
			'mat-stepper-answered': step_answered,
			'mat-stepper-introduced': step_introduced
		};
    	return cs;
    }
    isStepCollapsed(index){
    	let result = ( ( 
			
			(this.form_answers[index].value.toString().length>0 || this.form_data.liste_contenu[index].valeur == ",," )
    					 && this.form_answers_map[this.form_data.liste_contenu[index].id].updatable==true
					     //&& (this.form_data.liste_contenu[index].type_question_id!==5
						 && (this.form_data.liste_contenu[index].type_question_id!==9
					     && this.manual_collapsed[this.form_data.liste_contenu[index].id]==true
					 ) ) || 
    					this.manual_collapsed[this.form_data.liste_contenu[index].id]==true
						 )? true: false;

    	return result;
    }
    isStepIntroduced(index){
		//15/02/21 : je l'ai supprimé car ça donne quelque chose d'étrange
    	//return this.form_answers[index].value.toString().length==0;
    }
    isStepAnswered(index){
		return this.form_data.liste_contenu[index].valeur == ",," || ( (
			
		(this.form_answers_map[this.form_data.liste_contenu[index].id].value.toString().length>0 &&
		(this.form_data.liste_contenu[index].type_question_id!==8 || this.form_data.liste_contenu[index].type_question2_id!==3)))
    			// && this.form_data.liste_contenu[index].type_question_id!==5
    			 && this.form_data.liste_contenu[index].type_question_id!==9
    		     && this.form_answers_map[this.form_data.liste_contenu[index].id].updatable );
    }
    handleManualValidation(e,index,id){
    	e.preventDefault();
    	if(this.form_data.liste_contenu[index].type_question_id == 5  || 
			this.form_answers_map[this.form_data.liste_contenu[index].id].value.toString().length>0){
	    	let self = this, target = e.currentTarget.parentElement.parentElement.parentElement;
	    	this.render.setElementClass(target, 'mat-stepper-collapsed',true);
	    	setTimeout(function(){ self.getForm(self.form_answers_map[id]); }, 150);
    	}
    }
    handleToggleCollapseStep(e,id){
    	e.preventDefault();
    	let target = e.currentTarget.parentElement, state = (target.classList.value.indexOf('mat-stepper-collapsed')>-1);
    	this.render.setElementClass(target, 'mat-stepper-collapsed', !state);
    	this.manual_collapsed[id] = !state;
    }

    handleFirstStep(e){
    	this.data_index = 0;
    	this.storage.store(STORAGE_ID, { jeton: CREDENTIALS.jeton, index: this.data_index } );
    	this.getForm('first');
    }
    handlePrevStep(e){
    	this.data_index = (this.data_index>0)? this.data_index-1 : 0;
    	this.storage.store(STORAGE_ID, { jeton: CREDENTIALS.jeton, index: this.data_index } );
    	this.getForm('prev');
    }
    handleNextStep(e){
    	console.log('------------\n-> NEXT');
    	this.data_index++;
		this.spinner = true;
    	this.storage.store(STORAGE_ID, { jeton: CREDENTIALS.jeton, index: this.data_index } );
    	this.getForm('next');
    }
    handlePrint(e){
		window.print();
    }
    
}
