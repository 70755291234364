import { NgModule } from '@angular/core';
import {
    MatDialogModule,
    MatProgressSpinnerModule
} from "@angular/material";

const MaterialComponent = [
    MatDialogModule,
    MatProgressSpinnerModule
]

@NgModule({
  declarations: [],
  imports: [MaterialComponent],
  exports: [MaterialComponent]
})
export class MaterialModule { }





