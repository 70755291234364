import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LocationStrategy } from '@angular/common';;
import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { Ng2Webstorage } from 'ngx-webstorage';
import { ReactiveFormsModule, NG_VALIDATORS } from "@angular/forms";
import { MatButtonModule, MatStepperModule, MatCardModule, MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material";
import { MatIconModule } from '@angular/material/icon';

import { DynamicFormsCoreModule } from "@ng-dynamic-forms/core";
import { DynamicFormsMaterialUIModule } from "@ng-dynamic-forms/ui-material";

import { APP_ROUTES_PROVIDER, APP_ROUTES, APP_LOC_STRATEGY } from './app.router';
import { APP_CONFIG } from './app.config';
import { positiveNumberValidator, numberRangeValidator, timeHoursValidator, timeMinutesValidator } from "./app.validators";
import { CapitalizePipe } from './pipe/capitalize.pipe';
import { RemovebrPipe } from './pipe/removebr.pipe';
import { AppServices } from './app.services';
import { AppComponent } from './app.component';
import { ClientComponent } from './client/client.component';

import * as moment from 'moment/moment';
import { DialogExampleComponent } from './dialog-example/dialog-example.component';
import { MaterialModule } from './material/material.module';
moment.locale('fr');

// TMP
// import { TEST_DATAS } from "./model/test-datas.model";

@NgModule({
  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    ReactiveFormsModule,
    Ng2Webstorage,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatNativeDateModule,
    DynamicFormsCoreModule.forRoot(), 
    DynamicFormsMaterialUIModule,
    MaterialModule,
    APP_ROUTES
  ],
  entryComponents:[DialogExampleComponent], 

  declarations: [
    CapitalizePipe,
    RemovebrPipe,
    AppComponent,
    ClientComponent,
    DialogExampleComponent
  ],
  
  providers: [ 
  	APP_ROUTES_PROVIDER, 
    APP_CONFIG,
  	AppServices, 
  	{ provide: LocationStrategy, useClass: APP_LOC_STRATEGY },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  	{ provide: NG_VALIDATORS, useValue: positiveNumberValidator, multi: true },
    { provide: NG_VALIDATORS, useValue: numberRangeValidator, multi: true },
    { provide: NG_VALIDATORS, useValue: timeHoursValidator, multi: true },
    { provide: NG_VALIDATORS, useValue: timeMinutesValidator, multi: true }
  ],

  bootstrap: [AppComponent] })

export class AppModule { }
