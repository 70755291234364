import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

export function positiveNumberValidator(control: AbstractControl): ValidationErrors | null {
    let hasError = control.value ? (parseInt(control.value)<0) : false;
    return hasError ? {positiveNumberValidator: true} : null;
}
export function numberRangeValidator(control: AbstractControl): ValidationErrors | null {
    let min = control['min'], max = control['max'], value = control.value, hasError;
    hasError= (value)? ( (max)? (value<min || value>max) : (value<min) ) : false;
    return hasError ? {numberRangeValidator: true} : null;
}
export function timeHoursValidator(control: AbstractControl): ValidationErrors | null {
    let hasError = control.value ? ((control.value<0) || (control.value>23)) : false;
    return hasError ? {timeHoursValidator: true} : null;
}
export function timeMinutesValidator(control: AbstractControl): ValidationErrors | null {
    let hasError = control.value ? ((control.value<0) || (control.value>59)) : false;
    return hasError ? {timeMinutesValidator: true} : null;
}

export function dateRangeValidator(group: FormGroup): ValidationErrors | null {
    let dateArrival = group.get("arrivalDate").value as Date,
        dateDeparture = group.get("departureDate").value as Date,
        hasError = false;
    if (dateArrival && dateDeparture) { hasError = dateArrival >= dateDeparture || dateDeparture <= dateArrival; }
    return hasError ? {dateRangeValidator: true} : null;
}
