import { Component, Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';

import { API_URL, STORAGE_ID, VERSION, CREDENTIALS, CONTEXTUAL_MESSAGES } from './app.config';


@Injectable()
export class DatasService {
  
  constructor(public http: Http) { }

  updateForm(request_obj){
    let hs = new Headers( { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept':'*/*' }), data = { jeton: CREDENTIALS.jeton }, k;
    for (k in request_obj) { data[k] = request_obj[k]; }
    return this.http.post( API_URL, this.getFormUrlEncoded(data), { headers: hs } );
  }

  getFormUrlEncoded(data) {
    let k, ek, ev, result = [];
    for (k in data) { ek = encodeURIComponent(k); ev = encodeURIComponent(data[k]); result.push(ek + '=' + ev); }
    return result.join('&');
  }

}

@Injectable()
export class WindowService {
    constructor() {}
    getNativeWindow(){ return window; }
}

export var AppServices: Array<any> = [
  {provide: DatasService, useClass: DatasService},
  {provide: WindowService, useClass: WindowService}
];